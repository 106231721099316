import { cookie, redirectApps, prefix } from '~/utils/';

export const showPricing = (cb) => {

  if (
    cookie.getValue('flow') === 'chatapp' &&
    cookie.getValue('n_flow') === 'chatapp' &&
    !cookie.getValue('user_plan')
  ) {
    const isStaging = window.location.href.includes('staging');
    if (isStaging) {
      window.location.href = 'https://staging.ai-pro.org/pricing-redirect/?from=chatapp';
      return;
    }
    window.location.href = 'https://ai-pro.org/pricing-redirect/?from=chatapp';
    return;
  }

  if (cookie.getValue('rdct') && cookie.getValue('rdct') in redirectApps && !cookie.getValue('user_plan')) {
    window.location.href = `https://${prefix}ai-pro.org/pricing-redirect`;
    return;
  }

  let r_flow = false;
  switch(cookie.getValue("r_flow")) {
    case 'chatai': //this is chat.ai-pro.org in ai-pro.org
    case 'dreamphoto':
    case 'chatpdf':
      r_flow = true;
      break;
  }
  if(r_flow) {
    let wp_flow = cookie.getValue("flow");
    let register_logic_url = "/register";
    let wpBaseURL = "https://ai-pro.org";
    switch (wp_flow) {
      case '02':
        register_logic_url = '/register-b';
        break;
      case '03':
        register_logic_url = '/register-x';
        break;
      case '04':
        register_logic_url = '/select-account-type-d'; //slash
        break;
      case '05':
        register_logic_url = '/aurora-register'; //slash
        break;
      default:
        register_logic_url = register_logic_url;
    }
    window.location.href = wpBaseURL + register_logic_url;
    return;
  }
  cb();
};

// export const setCookieValue = (key, value, domain) => {
//   cookie.setValue(key, value)
// };

export const setCookieValue = (key, value, domain) => {
  const options = {
    path: '/',
    domain: domain,          // Ensure the domain is correctly set
    secure: true,            // Use 'secure' if your site is served over HTTPS
    sameSite: 'Lax'          // Or 'None' if cross-site requests need it
  };
  
  cookie.setValue(key, value, options);
};
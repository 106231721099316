import { createRoot } from 'react-dom/client';
import App from './App';
import './style.css';
import './mobile.css';
import { ApiErrorBoundaryProvider } from './hooks/ApiErrorBoundaryContext';
import { ChatProvider } from './hooks/ChatContext';
import { UserProvider } from './hooks/UserContext';

// Import theme styles
import './css-themes/aubergine.css';
import './css-themes/default-two.css';
import './css-themes/ochin.css';
import './css-themes/monument.css';
import './css-themes/work-hard.css';
import './css-themes/aubergine-classic.css';
import './css-themes/eggplant.css';
import './css-themes/hoth.css';
import './css-themes/mondrian.css';
import './css-themes/ultraviolet.css';
import './css-themes/mood-indigo.css';
import './css-themes/cmyk.css';
import './css-themes/nocturne.css';
import './css-themes/haberdashery.css';
import './css-themes/expensive.css';
import './css-themes/terminal.css';
import './css-themes/sweet-treat.css';
import './css-themes/banana.css';
import './css-themes/choco-mint.css';
import './css-themes/discotheque.css';
import './css-themes/tritanopia.css';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <ChatProvider>
      <UserProvider>
        <ApiErrorBoundaryProvider>
          <App />
        </ApiErrorBoundaryProvider>
      </UserProvider>
    </ChatProvider>,
  );
} else {
  console.error('No root container found');
}

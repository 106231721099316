import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

interface User {
  subscription_type: string;
}

interface UserContextType {
  user: User | null;
  loading: boolean;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const checkUser = () => {
    const userExists =
      typeof AIPRO_USER !== 'undefined' &&
      AIPRO_USER &&
      AIPRO_USER.subscription_type !== null &&
      AIPRO_USER.subscription_type !== 'BASIC';

    setUser(userExists ? (AIPRO_USER as User) : null);
    setLoading(false);

    if (userExists) {
      window.location.href = import.meta.env.VITE_REDIRECT_URL;
    }
  };

  useEffect(() => {
    checkUser();

    const intervalId = setInterval(checkUser, 100);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <UserContext.Provider value={{ user, loading }}>
      {children}
    </UserContext.Provider>
  );
};
